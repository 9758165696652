import { FaArrowRightLong } from "react-icons/fa6";
import olympicLogo from "../../../assets/home2/header/olympic-games.png";
import "./style.scss";
import { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { games_data } from "../data";
import ktspl from "../../../assets/home2/header/KTSPL-White-One.png";

const Games = ({ setIsMenuOpen }) => {
  const [selectedSportType, setSelectedSportType] = useState("visit");
  return (
    <div className="score-main">
      <div className="sports">
        <p
          className={selectedSportType === "records" ? "sports-active-btn" : ""}
          onClick={() => setSelectedSportType("records")}
        >
          Records
        </p>
        <p
          className={selectedSportType === "reports" ? "sports-active-btn" : ""}
          onClick={() => setSelectedSportType("reports")}
        >
          Reports
        </p>

        <p
          className={
            selectedSportType === "medallTally" ? "sports-active-btn" : ""
          }
          onClick={() => setSelectedSportType("medallTally")}
        >
          Medal Tally
        </p>
        <p
          className={selectedSportType === "visit" ? "sports-active-btn" : ""}
          onClick={() => setSelectedSportType("visit")}
        >
          Visit
        </p>
        <p
          className={selectedSportType === "about" ? "sports-active-btn" : ""}
          onClick={() => setSelectedSportType("about")}
        >
          About
        </p>
      </div>
      {games_data?.map((item) => {
        if (item.value === selectedSportType) {
          return (
            <>
              <div className="sport-description">
                <img src={item?.img} alt="" className={item?.heading} />
                <p style={{ fontWeight: "600" }}>{item?.heading}</p>
                <p className="des">{item?.description}</p>
                <div className="learn-more">
                  {item?.link && (
                    <>
                      {" "}
                      <Link
                        to={item?.link}
                        onClick={(e) => {
                          setIsMenuOpen(false);
                        }}
                        style={{
                          textDecoration: "none",
                          color: "white", // Default text color
                          transition: "color 0.3s", // Smooth transition for color change
                          "&:hover": {
                            color: "green", // Text color changes to white on hover
                          },
                        }}
                      >
                        {" "}
                        <p>Learn More</p>
                      </Link>
                      <FaArrowRightLong />
                    </>
                  )}
                </div>
              </div>
              {/* subsection to thew right */}

              <div className="sport-list">
                {item?.sideOptions?.map((el, index) => (
                  <Link
                    to={el?.link?.isRelativePath ? el?.link?.path : "/"}
                    // to={el?.link?.isRelativePath ? el?.link?.path : el?.link?.path ? el?.link?.path : '/'}
                    // {...(!el?.link?.isRelativePath
                    //   ? { target: "_blank", rel: el?.link?.path}
                    //   : {})}
                    onClick={() => {
                      if (el.link.isRelativePath) {
                        setIsMenuOpen(false);
                      }
                      if (!el?.link?.isRelativePath) {
                        window.open(el?.link?.path, el.link);
                      }
                    }}
                    style={{
                      textDecoration: "none",
                      color: "white", // Default text color
                    }}
                  >
                    <div className="each-game" key={index}>
                      {el.image && (
                        <div>
                          <div className="image-container-dropdowns">
                            <img src={el?.image} alt={el?.name} />
                          </div>
                        </div>
                      )}
                      <div className="des">
                        <p className="name">{el?.name}</p>
                        <p className="description">{el?.description}</p>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </>
          );
        }
      })}
      <div className="official-part">
        <img className="imgSize"src={ktspl} />
        <p className="official-partner colr">Official Technology Partner</p>
      </div>
    </div>
  );
};

export default Games;
