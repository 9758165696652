
export default function  calculateTimeRemaining ()  {
    const now = new Date();
    const expirationDate = new Date('2025-01-13T00:00:00');    // Set your expiration date here
    const timeDifference = expirationDate - now;

    if (timeDifference <= 0) {
      return {
        days: "0000",
        hour: "00",
        mins: "00",
        sec: "00",
      };
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return {
      days: days.toString().padStart(4, '0'),
      hour: hours < 10 ? `0${hours}` : `${hours}`,
      mins: minutes < 10 ? `0${minutes}` : `${minutes}`,
      sec: seconds < 10 ? `0${seconds}` : `${seconds}`,
    };
  };

