export const footer1 = [
  { name: "Points Table", path: "/" },
  { name: "Schedule", path: "/" },
  { name: "Teams", path: "/teams" },
  { name: "Competition Zone", path: "/" },
  { name: "Registrations", path: "/" },
  { name: "Login", path: "/" },
  // { name: "Games Culture", path: "/milestone/all" },
]

// export const sportsOptions = [
//   { name: "Aquatics", path: "/sports/aquatics" },
//   { name: "Archery", path: "/sports/archery" },
//   { name: "Athletics", path: "/sports/athletics" },
//   { name: "Badminton", path: "/sports/badminton" },
//   { name: "Basketball", path: "/sports/basketball" },
//   { name: "Boxing", path: "/sports/boxing" },
//   { name: "Cycling", path: "/sports/cycling" },
//   { name: "Equestrian", path: "/sports/equestrian" },
//   { name: "Fencing", path: "/sports/fencing" },
//   { name: "Football", path: "/sports/football" },
//   { name: "Gymnastics", path: "/sports/gymnastics" },
//   { name: "Hockey", path: "/" },
//   { name: "Handball", path: "/sports/handball" },
//   { name: "Judo", path: "/" },
//   { name: "Kabaddi", path: "/" },
//   { name: "Kayaking", path: "/" },
  

// ];


export const sportsOptions = [
  { name: "About Us", path: "/" },
  // { name: "Mascot", path: "/" },
  // { name: "Executive committee", path: "/" },
  // { name: "Partners", path: "/" },
  // { name: "Contact us", path: "/" },
  // { name: "Sponsors", path: "/" },
 
  

];

export const footer3 = [
  { name: "News", path: "/" },  
  { name: "Photos", path: "/" },  
  { name: "Videos", path: "/" }, 

];


export const footer4 = [
  { name: "Points Table", path: "/" },  
  { name: "Fixtures", path: "/" },  
  { name: "Match Result", path: "/" }, 
  { name: "Field of Play", path: "/" },
 

];