import { FaArrowRightLong } from "react-icons/fa6";
import { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { mileStone_data } from "../data";
import ktspl from "../../../assets/home2/header/KTSPL-White-One.png";


const MileStones = ({ setIsMenuOpen }) => {
  const [selectedSportType, setSelectedSportType] = useState("All");
  return (
    <div className="score-main">
      <div className="sports">
        {mileStone_data?.map((item) => {
          return (
            <p
              className={
                selectedSportType === item?.value ? "sports-active-btn" : ""
              }
              onClick={() => setSelectedSportType(item?.value)}
            >
              {item?.heading}
            </p>
          );
        })}
      </div>
      {mileStone_data?.map((item) => {
        if (item.value === selectedSportType) {
          return (
            <>
              <div className="sport-description">
                <img src={item?.img} alt="" className="img" />
                <p style={{ fontWeight: "600" }}>{item?.heading}</p>
                <p className="des">{item?.description}</p>
                <div className="learn-more">
                  <Link
                    to={item?.link}
                    onClick={(e) => {
                      setIsMenuOpen(false);
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    <p>Learn More</p>
                  </Link>
                  <FaArrowRightLong />
                </div>
              </div>
              {/* subsection to thew right */}
              <div className="sport-list">
                {item?.sideOptions?.map((el, index) => (
                  <div className="each-game" key={index}>
                     <div>
                      <div className="image-container-dropdowns">
                        <img src={el?.image} alt={el?.name} />
                      </div>
                    </div>

                    <div className="des">
                      <p className="name">{el?.name}</p>
                      <p
                        style={{
                          fontSize: "16px",
                          textAlign: "left",
                        }}
                        className="description"
                      >
                        {el?.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </>
          );
        }
      })}

<div className="official-part">
          <img className="imgSize" src={ktspl} />
          <p className="official-partner colr" >Official Technology Partner</p>
        </div>
    </div>
  );
};
export default MileStones;
