import { Link } from "react-router-dom";
import "./styles.scss";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const Index = () => {
  return (
    <div className="marquee enable-animation">
      <ul className="marquee__content">
        <li>
          <span className="marque-heading">Kho-Kho World Cup:</span>
          <a href={"#" || BASE_URL} className="marquee_title_label">
            The Kho Kho World Cup kicks off on January 13th at the Indira Gandhi
            Indoor Stadium in New Delhi.
          </a>
        </li>

        {/* <li>
          <span className="marque-heading">
            
            NEWS :{" "}
          </span>
        
          <a
            href={`${BASE_URL}latest/news/1`}
            className="marquee_title_label"
          >
         
            CM Dhami Holds Strategic Meeting on 38th National Games Preparations
          </a>
        </li>
        <li>
          <span className="marque-heading">
           
            OLYMPIAN FELICITATED :{" "}
          </span>
        
          <a
            href={`${BASE_URL}latest/photos`}
            className="marquee_title_label"
          >
          
            View Photos of Olympians from Uttarakhand
          </a>
        </li> */}
      </ul>
      <ul className="marquee__content">
        <li>
          <span className="marque-heading">Kho-Kho World Cup:</span>
          <a href={BASE_URL} className="marquee_title_label">
            The Kho Kho World Cup kicks off on January 13th at the Indira Gandhi
            Indoor Stadium in New Delhi.
          </a>
        </li>

        {/* <li>
          <span className="marque-heading">NEWS : </span>

          <a href={`${BASE_URL}latest/news/1`} className="marquee_title_label">
            
            CM Dhami Holds Strategic Meeting on 38th National Games Preparations
          </a>
        </li> */}
        {/* <li>
          <span className="marque-heading">
      
            OLYMPIAN FELICITATED :{" "}
          </span>
         
          <a href={`${BASE_URL}latest/photos`} className="marquee_title_label">
      
            View Photos of Olympians from Uttarakhand
          </a>
        </li> */}
      </ul>
    </div>
  );
};

export default Index;
