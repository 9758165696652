import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";
import ScheduleAndResults from ".";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import styles from "./SchedulePage.module.css";
import { sportToImgIcons } from "./VenueDates";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { differentSportsResults } from "utils/utils";
import { getReplacedName } from "utils/helper";
import uttrakhandimg from "../../assets/home2/Body/uttrakhandopen.png"
import { Col, Row } from "react-bootstrap";

const dateArray = [
  "19-October-2023",
  "20-October-2023",
  "21-October-2023",
  "22-October-2023",
  "23-October-2023",
  "24-October-2023",
  "25-October-2023",
  "26-October-2023",
  "27-October-2023",
  "28-October-2023",
  "29-October-2023",
  "30-October-2023",
  "31-October-2023",
  "01-November-2023",
  "02-November-2023",
  "03-November-2023",
  "04-November-2023",
  "05-November-2023",
  "06-November-2023",
  "07-November-2023",
  "08-November-2023",
  "09-November-2023",
];

const sportsName = [
  {
    sportName: "Opening & Closing Ceremonies",
    date: ["26 October 2023", "09 November 2023"],
    startDate: null,
    endDate: null,
    img: uttrakhandimg,
  },
  {
    sportName: "Badminton",
    startDate: "19 October 2023",
    endDate: "24 October 2023",
    routing: "badminton",
  },
  {
    sportName: "Netball",
    startDate: "22 October 2023",
    endDate: "27 October 2023",
    routing: "netball",
  },
  {
    sportName: "Basketball",
    startDate: "23 October 2023",
    endDate: "28 October 2023",
    routing: "basketball",
  },
  {
    sportName: "Gymnastics",
    startDate: "23 October 2023",
    endDate: "28 October 2023",
    routing: "gymnasium",
  },
  {
    sportName: "Rugby",
    startDate: "25 October 2023",
    endDate: "27 October 2023",
    routing: "rugby",
  },
  {
    sportName: "Weightlifting",
    startDate: "25 October 2023",
    endDate: "29 October 2023",
    routing: "weightlifting",
  },
  {
    sportName: "Mallakhamb",
    startDate: "26 October 2023",
    endDate: "28 October 2023",
    routing: "mallakhamb",
  },
  {
    sportName: "Pencak Silat",
    startDate: "26 October 2023",
    endDate: "29 October 2023",
    key: "pencak-silat",
    routing: "pencak-silat",
  },
  {
    sportName: "Modern Pentathlon",
    startDate: "26 October 2023",
    endDate: "29 October 2023",
    routing: "modern-pentathlon",
  },
  {
    sportName: "Fencing",
    startDate: "26 October 2023",
    endDate: "30 October 2023",
    routing: "fencing",
  },
  {
    sportName: "Billiards & Snookers",
    startDate: "27 October 2023",
    endDate: "30 October 2023",
    key: "billiard-&-snooker",
    routing: "billiard-&-snooker",
  },
  {
    sportName: "Football (Women)",
    startDate: "27 October 2023",
    endDate: "5 November 2023",
    routing: "football",
  },
  {
    sportName: "Rowing",
    startDate: "28 October 2023",
    endDate: "1 November 2023",
    routing: "rowing",
  },
  {
    sportName: "Beach Football",
    startDate: "28 October 2023",
    endDate: "1 November 2023",
    routing: "beach-football",
  },
  {
    sportName: "Table Tennis",
    startDate: "29 October 2023",
    endDate: "2 November 2023",
    routing: "table-tennis",
  },
  {
    sportName: "Athletics",
    startDate: "29 October 2023",
    endDate: "3 November 2023",
    routing: "athletics",
  },
  {
    sportName: "Aquatics",
    startDate: "29 October 2023",
    endDate: "4 November 2023",
    routing: "aquatics",
  },
  {
    sportName: "Archery",
    startDate: "29 October 2023",
    endDate: "6 November 2023",
    routing: "archery",
  },
  {
    sportName: "Rollball",
    startDate: "30 October 2023",
    endDate: "2 November 2023",
    routing: "rollball",
  },
  {
    sportName: "Sepak Takraw",
    startDate: "30 October 2023",
    endDate: "3 November 2023",
    key: "sepak-takraw",
    routing: "sepak-takraw",
  },
  {
    sportName: "Lawn Tennis",
    startDate: "30 October 2023",
    endDate: "5 November 2023",
    routing: "lawn-tennis",
  },
  {
    sportName: "Hockey",
    startDate: "30 October 2023",
    endDate: "8 November 2023",
    routing: "hockey",
  },
  {
    sportName: "Football (Men)",
    startDate: "30 October 2023",
    endDate: "8 November 2023",
    routing: "football",
  },
  {
    sportName: "Gatka",
    startDate: "31 October 2023",
    endDate: "2 November 2023",
    routing: "gatka",
  },
  {
    sportName: "Taekwondo",
    startDate: "31 October 2023",
    endDate: "3 November 2023",
    routing: "taekwondo",
  },
  {
    sportName: "Mini Golf",
    startDate: "1 November 2023",
    endDate: "3 November 2023",
    routing: "mini-golf",
  },
  {
    sportName: "Wrestling",
    startDate: "1 November 2023",
    endDate: "3 November 2023",
    routing: "wrestling",
  },
  {
    sportName: "Wushu",
    startDate: "1 November 2023",
    endDate: "4 November 2023",
    routing: "wushu",
  },
  {
    sportName: "Volleyball",
    startDate: "1 November 2023",
    endDate: "5 November 2023",
    routing: "volleyball",
  },
  {
    sportName: "Squash",
    startDate: "1 November 2023",
    endDate: "5 November 2023",
    routing: "squash",
  },
  {
    sportName: "Lawn Bowls",
    startDate: "1 November 2023",
    endDate: "8 November 2023",
    key: "lawn-bowl",
    routing: "lawn-bowl",
  },
  {
    sportName: "Boxing",
    startDate: "1 November 2023",
    endDate: "8 November 2023",
    routing: "boxing",
  },
  {
    sportName: "Cycling (Track Race)",
    startDate: "2 November 2023",
    endDate: "5 November 2023",
    routing: "cycling",
  },
  {
    sportName: "Shooting",
    startDate: "2 November 2023",
    endDate: "9 November 2023",
    routing: "shooting",
  },
  {
    sportName: "Rowing (Coastal/Tidal Sport)",
    startDate: "3 November 2023",
    endDate: "6 November 2023",
    routing: "rowing",
  },
  {
    sportName: "Yachting",
    startDate: "3 November 2023",
    endDate: "8 November 2023",
    routing: "yachting",
  },
  {
    sportName: "Triathlon",
    startDate: "4 November 2023",
    endDate: "7 November 2023",
    routing: "triathlon",
  },
  {
    sportName: "Canoeing & Kayaking",
    startDate: "4 November 2023",
    endDate: "7 November 2023",
    routing: "canoeing-&-kayaking",
  },
  {
    sportName: "Beach Volleyball",
    startDate: "4 November 2023",
    endDate: "7 November 2023",
    routing: "beach-volleyball",
  },
  {
    sportName: "Handball",
    startDate: "4 November 2023",
    endDate: "8 November 2023",
    routing: "handball",
  },
  {
    sportName: "Kabaddi",
    startDate: "4 November 2023",
    endDate: "8 November 2023",
    routing: "kabaddi",
  },
  {
    sportName: "Kho-Kho",
    startDate: "4 November 2023",
    endDate: "8 November 2023",
    routing: "kho-kho",
  },
  {
    sportName: "Lagori",
    startDate: "5 November 2023",
    endDate: "6 November 2023",
    routing: "lagori",
  },
  {
    sportName: "Beach Handball",
    startDate: "5 November 2023",
    endDate: "9 November 2023",
    routing: "beach-handball",
  },
  {
    sportName: "Golf",
    startDate: "5 November 2023",
    endDate: "9 November 2023",
    routing: "golf",
  },
  {
    sportName: "Yogasana",
    startDate: "5 November 2023",
    endDate: "9 November 2023",
    routing: "yogasana",
  },
  {
    sportName: "Sqay Martial Arts",
    startDate: "6 November 2023",
    endDate: "8 November 2023",
    key: "sqay-martial-arts",
    routing: "sqay-martial-arts",
  },
  {
    sportName: "Judo",
    startDate: "6 November 2023",
    endDate: "8 November 2023",
    routing: "judo",
  },
  {
    sportName: "Kalaripayattu",
    startDate: "7 November 2023",
    endDate: "8 November 2023",
    routing: "kalaripayattu",
  },
  {
    sportName: "Cycling (Road Race)",
    startDate: "8 November 2023",
    endDate: "9 November 2023",
    routing: "cycling",
  },
];
const getPropertyNoCase = (obj, prop) => {
  return obj[
    Object.keys(obj)?.find((key) =>
      prop?.toLowerCase()?.includes(key.toLowerCase())
    )
  ];
};

function ScheduleOverview() {
  const history = useHistory();
  const currentDate = dayjs().format("DD-MMMM-YYYY");
  const currentDateIndex = dateArray.indexOf(currentDate);
  const [startIndex, setStartIndex] = useState(
    currentDateIndex > -1 ? currentDateIndex : 0
  );
  const [visibleDates, setVisibleDates] = useState(8);
  const dateContainerRef = useRef(null);
  const dateCardWidth = 100;

  useEffect(() => {
    const calculateVisibleDates = () => {
      if (dateContainerRef.current.offsetWidth) {
        const containerWidth = dateContainerRef.current.offsetWidth;
        const newVisibleDates = Math.floor(containerWidth / dateCardWidth);
        setVisibleDates(newVisibleDates);
      }
    };

    calculateVisibleDates();

    window.addEventListener("resize", calculateVisibleDates);

    return () => {
      window.removeEventListener("resize", calculateVisibleDates);
    };
  }, []);

  const handleClickRoute = (item) => {
    if (item?.routing) {
      differentSportsResults?.includes(item?.routing)
        ? window.open(
          `https://tsr.37nationalgamesgoa.in/scoring-scheduling/sport/${getReplacedName(
            item?.routing
          )}`
        )
        :
        history.push(`/sports/${item?.routing}`);
    }
  };

  const handlePrevClick = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (startIndex < dateArray.length - visibleDates) {
      setStartIndex(startIndex + 1);
    }
  };
  const isNextDisabled = startIndex + visibleDates >= dateArray.length;

  document.onkeydown = (e) => {
    e = e || window.event;
    if (e.key === "ArrowLeft") {
      handlePrevClick();
    } else if (e.key === "ArrowRight") {
      handleNextClick();
    }
  };

  return (
    <ScheduleAndResults>
      <div className={`${styles.dateTableView}`}>
        <div
          className={`grid grid-cols-10 place-items-center schedule-header ${styles.gridOverview}`}
        >
          <div
            style={{ color: "rgba(255, 255, 255, 1.2)" }}
            className="fw-bolder col-span-2 text-lg"
          >
            Sports / Discipline
          </div>
          <div className="col-span-8 items-center d-flex gap-2 w-100">
            <div className={styles?.arrowContainer}>
              <BsArrowLeftCircle
                className={styles?.arrowIcon}
                onClick={handlePrevClick}
              />
            </div>
            <div className={styles?.dateContainer} ref={dateContainerRef}>
              {dateArray
                .slice(startIndex, startIndex + visibleDates)
                .map((item, id) => (
                  <div key={id + "-calendar"}>
                    <div className={styles?.dateCard}>
                      <p className="m-0">{dayjs(item)?.format("MMM")}</p>
                      <p className="text-3xl" style={{ marginBottom: "3px" }}>
                        {dayjs(item)?.format("DD")}
                      </p>
                    </div>
                    <p
                      style={{
                        background: '#DE129B',
                        color: "white",
                        borderBottomLeftRadius: "8px",
                        borderBottomRightRadius: "8px",
                      }}
                    >
                      {dayjs(item)?.format("ddd")}
                    </p>
                  </div>
                ))}
            </div>
            <div className={styles?.arrowContainer}>
              <BsArrowRightCircle
                className={`${styles?.arrowIcon} ${isNextDisabled ? styles?.disabled : ""
                  }`}
                onClick={handleNextClick}
                disabled={isNextDisabled}
              />
            </div>
          </div>
        </div>
        <div>
          <div style={{ background: "#F5F5F5" }} className="p-1 rounded-2">
            <div>
              {sportsName?.map((item, id) => {
                console.log("sportName===>", item.sportName)
                return (

                  <div
                    key={id + "-sportTiles"}
                    className="grid grid-cols-10"
                    style={{
                      background: "white",
                      borderRadius: "10px",
                      marginBottom: sportsName?.length - 1 !== id && "7px",
                    }}
                    onClick={() => handleClickRoute(item)}
                  >
                    <div
                      className={`col-span-2 flex align-items-center gap-2 d-flex ${styles.leftText}`}
                    >
                      <img
                        loading="lazy"
                        src={
                          getPropertyNoCase(
                            sportToImgIcons,
                            item?.key ?? item?.sportName
                          ) ?? item?.img
                        }
                        alt={`Icon for ${item?.sportName}`}
                        className={`icon  rounded-2 m-2 ${item.sportName === "Opening & Closing Ceremonies" ? "" : "linergradient"}`}
                        style={{
                          width: "60px",
                          height: "60px",
                          objectFit: "contain",
                        }}
                      />
                      <p
                        style={{
                          marginBottom: "5px",
                          fontSize: "20px",
                          textAlign: "start",
                        }}
                      >
                        {item?.sportName}
                      </p>
                    </div>
                    {/* <Row>
                      <Col>
                        <Row className="gy-3">
                          {Array.from({ length: 15 }, (_, index) => (
                            <Col key={index} xs={4} sm={3} md={2} lg={4}>
                              <div className="sport_cube_box"></div>
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row> */}
                    <div className="col-span-8 items-center d-flex gap-2 w-100 ">
                    <div className="col-span-1 items-center d-flex gap-2 w-100 justify-content-between">
                      {dateArray
                        .slice(startIndex, startIndex + visibleDates)
                        .map((date, dateId) => {
                          const isDateWithinRange =
                            dayjs(date, "DD-MMM-YYYY").isAfter(
                              dayjs(item.startDate, "DD MMMM YYYY").subtract(
                                1,
                                "day"
                              )
                            ) &&
                            dayjs(date, "DD-MMM-YYYY").isBefore(
                              dayjs(item.endDate, "DD MMMM YYYY").add(1, "day")
                            );
                          const isStartDate =
                            date ===
                            dayjs(item?.date?.[0], "DD MMMM YYYY").format(
                              "DD-MMMM-YYYY"
                            );
                          const isEndDate =
                            date ===
                            dayjs(item.date?.[1], "DD MMMM YYYY").format(
                              "DD-MMMM-YYYY"
                            );

                          const isFirstOrLastDate =
                            date !== dayjs().format("DD-MMMM-YYYY") &&
                            ((dateId === 0 && isStartDate) ||
                              (dateId === visibleDates - 1 && isEndDate));

                          return (
                            <div
                              key={dateId + "-dot"}
                              className="w-100 text-center flex justify-content-center align-content-center position-relative"
                              style={{
                                top: "45%",
                              }}
                            >
                              {isFirstOrLastDate ? (
                                <div
                                  className="font-bold"
                                  style={{
                                    width: "16px",
                                    height: "16px",
                                    borderRadius: "50%",
                                    backgroundColor: "#0b9444",
                                  }}
                                ></div>
                              ) : null}
                              {isDateWithinRange ? (
                                <div
                                  style={{
                                    width: "16px",
                                    height: "16px",
                                    borderRadius: "50%",
                                    backgroundColor: "#0b9444",
                                  }}
                                ></div>
                              ) : null}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </ScheduleAndResults>
  );
}

export default ScheduleOverview;
