import { FaArrowRightLong } from "react-icons/fa6";
import olympicLogo from "../../../assets/home2/header/olympic-games.png";
import Indigenous from "../../../assets/scores-png/Indigenous.png";
import "./style.scss";
import { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { sportList, IndigenousportsList } from "../data";
import ktspl from "../../../assets/home2/header/KTSPL-White-One.png";
import { Height } from "@mui/icons-material";

const Scores = ({ setIsMenuOpen }) => {
  const [selectedSportType, setSelectedSportType] = useState("All Sports");

  return (
    <div className="score-main">
      <div className="sports">
        <p
          className={
            selectedSportType === "All Sports" ? "sports-active-btn" : ""
          }
          onClick={() => setSelectedSportType("All Sports")}
        >
          All Sports
        </p>
        <p
          className={
            selectedSportType === "Olympics Sports" ? "sports-active-btn" : ""
          }
          onClick={() => setSelectedSportType("Olympics Sports")}
        >
          Olympics Sports
        </p>
        <p
          className={
            selectedSportType === "Indigenous Sports" ? "sports-active-btn" : ""
          }
          onClick={() => setSelectedSportType("Indigenous Sports")}
        >
          Indigenous Sports
        </p>
      </div>

      {selectedSportType === "Olympics Sports" ||
      selectedSportType === "All Sports" ? (
        <div className="sport-description">
          <img src={olympicLogo} alt="" className="olympic-img" />
          <p style={{ fontWeight: "600" }}>Olympics Sports</p>
          <p className="des">
           The 38th National Games of India will feature a diverse range of sports, including Athletics, Aquatics, Archery, Badminton, Basketball, Boxing, Football, Gymnastics, Hockey, Judo, Kabaddi, Table Tennis, Shooting, Weightlifting, Wrestling, and traditional sports like Yoga and Mallakhamb.
          </p>
          <div className="learn-more">
            <p>Learn More</p>
            <FaArrowRightLong />
          </div>
        </div>
      ) : selectedSportType === "Indigenous Sports" ? (
        <div className="sport-description">
          <img src={Indigenous} alt="" className="olympic-img" />
          <p style={{ fontWeight: "600" }}>Indigenous Sports</p>
          <p className="des">
            Indigenous sports hold a significant place in India's rich cultural
            heritage, representing traditional games that have been passed down
            through generations. For the 38th National Games of India, the
            inclusion of indigenous sports not only celebrates the diversity of
            the nation but also promotes physical fitness, community spirit, and
            cultural pride.
          </p>
          <div className="learn-more">
            <p>Learn More</p>
            <FaArrowRightLong />
          </div>
        </div>
      ) : null}

      <div
        className={`sport-list ${
          selectedSportType === "Indigenous Sports" ? "indigenous-sports" : ""
        }`}
      >
        {selectedSportType === "Olympics Sports" ? (
          <>
            {sportList
              ?.sort((a, b) => a.name.localeCompare(b.name))
              ?.map((el, index) => {
                return (
                  <Link
                    to={el?.link?.isRelativePath ? el?.link?.path : "/"}
                    {...(!el?.link?.isRelativePath
                      ? { target: "_blank", rel: "noopener noreferrer" }
                      : {})}
                    onClick={() => {
                      if (el?.link?.isRelativePath) {
                        setIsMenuOpen(false);
                      }
                    }}
                    style={{ textDecoration: "none", color: "white" }}
                    key={index}
                  >
                    <div className="each-game">
                      <div>
                        <div className="image-container-dropdowns">
                          <img src={el?.image} alt={el?.name} />
                        </div>
                      </div>

                      <div className="des">
                        <p className="name">{el?.name}</p>
                        <p className="description">{el?.description}</p>
                      </div>
                    </div>
                  </Link>
                );
              })}
          </>
        ) : selectedSportType === "Indigenous Sports" ? (
          <>
            {IndigenousportsList?.sort((a, b) =>
              a.name.localeCompare(b.name)
            )?.map((el, index) => (
              <Link
                to={el?.link?.isRelativePath ? el?.link?.path : "/"}
                {...(!el?.link?.isRelativePath
                  ? { target: "_blank", rel: "noopener noreferrer" }
                  : {})}
                onClick={() => {
                  if (el?.link?.isRelativePath) {
                    setIsMenuOpen(false);
                  }
                }}
                style={{ textDecoration: "none", color: "white" }}
                key={index}
              >
                <div className="each-game">
                  <div>
                    <div className="image-container-dropdowns">
                      <img src={el?.image} alt={el?.name} />
                    </div>
                  </div>
                  <div className="des">
                    <p className="name">{el?.name}</p>
                    <p className="description">{el?.description}</p>
                  </div>
                </div>
              </Link>
            ))}
          </>
        ) : (
          [
            ...new Map(
              [...IndigenousportsList, ...sportList].map((sport) => [
                sport.name,
                sport,
              ])
            ).values(),
          ]
            .sort((a, b) => a.name.localeCompare(b.name))
            ?.map((el, index) => {
              return (
                <Link
                  to={el?.link?.isRelativePath ? el?.link?.path : "/"}
                  {...(!el?.link?.isRelativePath
                    ? { target: "_blank", rel: "noopener noreferrer" }
                    : {})}
                  onClick={() => {
                    if (el?.link?.isRelativePath) {
                      setIsMenuOpen(false);
                    }
                  }}
                  style={{ textDecoration: "none", color: "white" }}
                  key={index}
                >
                  <div className="each-game">
                    <div>
                      <div className="image-container-dropdowns">
                        <img src={el?.image} alt={el?.name} />
                      </div>
                    </div>
                    <div className="des">
                      <p className="name">{el?.name}</p>
                      <p className="description">{el?.description}</p>
                    </div>
                  </div>
                </Link>
              );
            })
        )}
      </div>

      <div className="official-part">
        <img className="imgSize" src={ktspl} />
        <p className="official-partner colr">Official Technology Partner</p>
      </div>
    </div>
  );
};
export default Scores;
